<script>
import { reportMonthlyService } from "../../../helpers/backend/report_monthly.service";

// import { moment } from "moment";
/**
 * Transactions component
 */
export default {
  data() {
    return {
      data: [],
      error: null,
      filter_type: "",
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "principle_name",
      sortDesc: false,
      showDismissibleAlert: false,
      active: true,
      // reportDateTo: new Date(new Date().setHours(7, 0, 0, 0)).toISOString(),
      reportDateFrom: new Date(new Date().setHours(7, 0, 0, 0)).toISOString(),
      // reportYear: "2021",
      // ... TODO:  nanti coba ambil dari DB ada year berapa aja
      // reportYearOptions: ["2020", "2021"], 
      // reportMonthFrom: "January",
      // reportMonthTo: "January",
      reportMonthOptions: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      reportType: "Principle",
      reportTypeOptions: ["Principle", "Machine", "Location", "Profit Sharing"],
      reportPrinciple: "",
      reportPrincipleOptions: [],
      dataToCsv: [],
      csvHeader: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length;
    },
    report_data_name() {
      return "Transaction_Report_" + this.reportType + "_" + this.reportPrinciple + "_" + new Date(this.reportDateFrom).getFullYear() + "_" + this.reportMonthOptions[new Date(this.reportDateFrom).getMonth()] +".xls"
    },
    processedData() {
      this.clearCsvData();
      if(this.reportType == 'Principle'){
        return this.data.reduce((inc, element) => {
          inc.push({
            principle_name: element.principle_name,
            product_name: element.product_name,
            report_date: element.report_date.split("T")[0],
            product_count: element.product_count,
          })
          
          // initialize column for dataToCsv
          let temp_col_dataToCsv = {
            // 'Principle Name' : element.principle_name,
            'Product Name': '',
            // 'Total Refill': 0,
            // Subtotal: 0
          }
          for (var i=1; i<=(new Date(new Date(this.reportDateFrom).getFullYear(), new Date(this.reportDateFrom).getMonth() + 1, 0).getDate()); i++) {
            temp_col_dataToCsv['Day ' + i] = 0;
          }
          const find = this.dataToCsv.find((item) => (item['Product Name'] == element.product_name) )
          if (find){
            find['Day ' + (new Date(element.report_date).getDate())] += element.product_count
            find['Total Refill'] += element.product_count
            find['Subtotal'] = find['Subtotal'] + element.product_count * element.product_price
          } else {
            // temp_col_dataToCsv['Principle Name'] = element.principle_name
            temp_col_dataToCsv['Product Name'] = element.product_name
            temp_col_dataToCsv['Day ' + new Date(element.report_date).getDate()] = element.product_count
            temp_col_dataToCsv['Total Refill'] = element.product_count
            temp_col_dataToCsv['Subtotal'] = element.product_count * element.product_price

            this.dataToCsv.push(temp_col_dataToCsv)
          }
          return inc
        }, [])
      }
      else if(this.reportType == 'Machine'){
      this.clearCsvData();
        return this.data.reduce((inc, element) => {
          inc.push({
            location: element.location,
            product_name: element.product_name,
            report_date: element.report_date.split("T")[0],
            product_count: element.product_count,
          })
          
          // initialize column for dataToCsv
          let temp_col_dataToCsv = {
            // principle_name : '',
            'Location' : '',
            'Product Name' : '',
            // 'Total Refill' : 0,
            // 'Subtotal' : 0
          }
          for (var i=1; i<=(new Date(new Date(this.reportDateFrom).getFullYear(), new Date(this.reportDateFrom).getMonth() + 1, 0).getDate()); i++) {
            temp_col_dataToCsv['Day ' + i] = 0;
          }
          const find = this.dataToCsv.find((item) => (item['Product Name'] == element.product_name && item['Location'] == element.location) )
          if (find){
            find['Day ' + (new Date(element.report_date).getDate())] += element.product_count
            find['Total Refill'] += element.product_count
            find['Subtotal'] = find['Subtotal'] + element.product_count * element.product_price
          } else {
            // temp_col_dataToCsv.principle_name = element.principle_name
            temp_col_dataToCsv['Location'] = element.location
            temp_col_dataToCsv['Product Name'] = element.product_name
            temp_col_dataToCsv['Day ' + new Date(element.report_date).getDate()] = element.product_count
            temp_col_dataToCsv['Total Refill'] = element.product_count
            temp_col_dataToCsv['Price per Unit'] = element.product_price
            temp_col_dataToCsv['Subtotal'] = element.product_count * element.product_price

            this.dataToCsv.push(temp_col_dataToCsv)
          }
          return inc
        }, [])
      }
      else if(this.reportType == 'Location'){
        this.clearCsvData();
        return this.data.reduce((inc, element) => {
          inc.push({
            location: element.location,
            report_date: element.report_date.split("T")[0],
            product_count: element.product_count,
          })
          
          // initialize column for dataToCsv
          let temp_col_dataToCsv = {
            // principle_name : '',
            'Location' : '',
            // 'Total Refill' : 0,
            // 'Subtotal' : 0
          }
          for (var i=1; i<=(new Date(new Date(this.reportDateFrom).getFullYear(), new Date(this.reportDateFrom).getMonth() + 1, 0).getDate()); i++) {
            temp_col_dataToCsv['Day ' + i] = 0;
          }
          const find = this.dataToCsv.find((item) => (item['Product Name'] == element.product_name && item['Location'] == element.location) )
          if (find){
            find['Day ' + (new Date(element.report_date).getDate())] += element.product_count
            find['Total Refill'] += element.product_count
            find['Subtotal'] = find['Subtotal'] + element.product_count * element.product_price
          } else {
            // temp_col_dataToCsv.principle_name = element.principle_name
            temp_col_dataToCsv['Location'] = element.location
            temp_col_dataToCsv['Day ' + new Date(element.report_date).getDate()] = element.product_count
            temp_col_dataToCsv['Total Refill'] = element.product_count
            temp_col_dataToCsv['Subtotal'] = element.product_count * element.product_price

            this.dataToCsv.push(temp_col_dataToCsv)
          }
          return inc
        }, [])
      }
      else if(this.reportType == 'Profit Sharing'){
        this.clearCsvData();
        return this.data.reduce((inc, element) => {
          inc.push({
            'Location': element.location,
            'Client': element.principle_name,
            // report_month: this.reportMonthOptions[element.report_month-1],
            'Gross Sales': element.gross_sales,
            'Net Sales': element.gross_sales - 0, // TODO: reduce with amount profit sharing
            '% Profit Sharing': 0,
            'Amount Profit Shating': 0,
            // machine_id: element.machine_id
          })
          this.dataToCsv = inc;
          
          return inc
        }, [])
      }

      return this.data
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length;
    this.fetchPrinciple();
    this.$emit(
      "onDateChange",
      new Date().toISOString().split("T")[0] + "T00:00:00Z"
    );
    if (this.totalRows == 0){
      this.error = "There is no data"
      this.loading = false;
      this.showDismissibleAlert = true;
    }
  },
  watch: {
    reportDateFrom: function () {
      if (!this.reportDateFrom.includes("T")) {
        this.reportDateFrom += "T00:00:00Z";
      }
      this.fetchData();
    },
    reportType: function () {
      this.fetchData();
    },
    reportPrinciple: function(){
      this.fetchData();
    },

  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setActive(b) {
      this.active = b;
    },
    refreshList() {
      this.data = [];
      this.dataToCsv = [];
      reportMonthlyService
        .getReportMonthlySummary(
          this.reportDateFrom,
          this.reportPrinciple,
          this.reportType
        )
        .then(
          (data) => {
            this.data = data.report;
            this.loading = false;

            if(data.report == null || data.report.length == 0){
              this.error = "There is no data"
              this.showErrorMessage();
            }else{
              this.totalRows = data.report.length;
              this.showDismissibleAlert = false;
            }

          },
          (err) => {
            this.error =
              "Failed to fetch report data. Error : " +
              err +
              "  Dissmiss to refresh";
            // this.loading = false;
            // this.showDismissibleAlert = true;
            this.showErrorMessage()
          }
        );
    },
    fetchData() {
      // this.error = null;
      // this.loading = true;
      // this.showDismissibleAlert = false;
      // this.dataToCsv = []
      
      this.refreshList();
    },
    fetchPrinciple(){
      reportMonthlyService
        .getAllPrinciple()
        .then(
          (data) => {
            data.principles.map(item => { this.reportPrincipleOptions.push(item.name) })
            this.reportPrinciple = this.reportPrincipleOptions[0]
            this.loading = false;
          },
          (err) => {
            this.error =
              "Failed to fetch report data. Error : " +
              err +
              "  Dissmiss to refresh";
            this.loading = false;
            this.showDismissibleAlert = true;
          }
        );
    },
    displayPrice(price) {
      if (Number.isInteger(price)) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          maximumFractionDigits: 0,
        }).format(price)
      } else {
        return price
      }
    },
    clearCsvData(){
      this.dataToCsv = []
      this.csvHeader = this.reportPrinciple
      if (this.reportType == 'Profit Sharing'){
        this.csvHeader = 'Profit Sharing'
      }
    },
    showErrorMessage(){
      this.loading = false;
      this.showDismissibleAlert = true;
    }
  },
};
</script>
<template>
  <div v-if="active">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <label for="datepicker-from">From</label>
            <b-form-datepicker
              id="datepicker-from"
              v-model="reportDateFrom"
            />
          </div>

          <div class="col-sm-12 col-md-4">
            <label for="cmb-report-type">Principle Name</label>
            <b-form-select
              id="cmb-report-type"
              v-model="reportPrinciple"
              :options="reportPrincipleOptions"
            />
          </div>
          <div class="col-sm-12 col-md-4">
            <label for="cmb-report-type">Report Type</label>
            <b-form-select
              id="cmb-report-type"
              v-model="reportType"
              :options="reportTypeOptions"
            />
          </div>
        </div>
        <template v-if="data.length > 0">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              :items="processedData"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="float-left">
                <download-excel type="xls" :name="report_data_name" :data="dataToCsv" :header="csvHeader">
                  <b-button variant="light" style="padding:10px;margin:5px" >Download Data
                    <i class="ri-download-2-line"></i>
                  </b-button>
                </download-excel>
              </div>
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.row-content:first-child {
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid whitesmoke;
}
</style>